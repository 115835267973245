import _ from 'lodash';
import { stringFormatter } from './index';

export interface DeepLink {
  host: string;
  pathname?: string;
  query: any;
}

export function deepLinkFormat(
  deepLink: DeepLink,
  language = 'en',
  variables: { [name: string]: string } = {},
): DeepLink {
  const query: any = {};

  Object.keys(deepLink.query || {}).forEach((name) => {
    // $FlowIssue
    const value: { [name: string]: string } | string = deepLink.query[name];
    let template;
    if (_.isObject(value)) {
      // $FlowIssue
      template = language in value ? value[language] : value.en || '';
    } else {
      template = value;
    }

    let postProcessedValue;
    if (typeof template === 'string') {
      postProcessedValue = stringFormatter((template || '').toString(), {
        language,
        // deviceId: getUUID(state),
        // $FlowFixMe
        ...variables,
      });
    } else {
      postProcessedValue = template;
    }

    query[name] = postProcessedValue;
  });
  deepLink.query = query;
  return deepLink;
}

export function objectFormat(
  deepLink: any,
  language = 'en',
  variables: { [name: string]: string } = {},
): any {
  const query: any = {};

  Object.keys(deepLink).forEach((name) => {
    // $FlowIssue
    const value: { [name: string]: string } | string = deepLink[name];
    let template;
    if (_.isObject(value)) {
      // $FlowIssue
      template = language in value ? value[language] : value.en || '';
    } else {
      template = value;
    }

    let postProcessedValue;
    if (typeof template === 'string') {
      postProcessedValue = stringFormatter((template || '').toString(), {
        language,
        // deviceId: getUUID(state),
        // $FlowFixMe
        ...variables,
      });
    } else {
      postProcessedValue = template;
    }

    query[name] = postProcessedValue;
  });
  return query;
}
