import _ from 'lodash';
import React from 'react';
import { goto } from '../../../../actions/historyActions';
import { isExternalLink } from '../../../../utils';

import ImgCloseButton from '../../../../assets/images/chatbot/icon-16-px-functional-cross-blue@3x.png';
import ImgFooter from '../../../../assets/images/chatbot/speech-bubble-footer-bg@3x.png';
import ImgHeaderBg from '../../../../assets/images/chatbot/speech-bubble-header-bg@3x.png';
import ImgTitleBgLeft from '../../../../assets/images/chatbot/speech-bubble-title-left@3x.png';
import ImgTitleBgRight from '../../../../assets/images/chatbot/speech-bubble-title-right@3x.png';
import {
  getCustomerIsCwp,
  getCustomerIsLife,
} from '../../../../selectors/userSelectors';
import { select } from '../../../../stores';
import { trackBannerAction } from '../../../../utils/firebaseAnalyticsEvents';
import { getGaPagePath } from '../../../../utils/generalGaEventAnalytics';

import './styles.scss';

interface BannerContentProps {
  pageID: string; // handled on parent level
  bubbleSwitch?: boolean;
  headerTitle?: string;
  content: string; //=text
  buttonText: string; // =ctaButtonText
  landingUrl: string;
  closeText: string; // =closeText
  appearAfterSecond?: number;
  dismissAfterSecond?: number;
  startDate: string; // filtered from API
  endDate: string; // filtered from API
  occasionDay?: string;
  gaId?: string;

  closeMode?: string;
  shouldShowCloseIcon?: boolean;
  platform?: string;
  buttonText2?: string;
  landingUrl2?: string;
}

interface Props {
  language: string;
  onClose?: (sbId: string, closeMode: string, sbDetails: any) => void;
  bannerContent: BannerContentProps;
}

const SB_MIN_APPEAR_AFTER_SECOND = 1;
const SB_DEFAULT_BUBBLE_SWTICH = true;
const SB_DEFAULT_CLOSE_TEXT = '';
const SB_DEFAULT_APPEAR_AFTER_SECOND = 0;
const SB_DEFAULT_DISMISS_AFTER_SECOND = 0;
const SB_DEFAULT_CLOSE_MODE = 'forever';
const SB_DEFAULT_SHOULD_SHOW_CLOSE_ICON = true;

let throttledCtaBtnClickFunc: any;
export const SpeechPopUp = ({ language, onClose, bannerContent }: Props) => {
  const [hasBeenClosed, setHasBeenClosed] = React.useState(true);
  const [shouldAppearNow, setShouldAppearNow] = React.useState(true);
  const {
    headerTitle = '',
    content,
    buttonText,
    landingUrl,
    buttonText2 = '',
    landingUrl2 = '',
    closeText = SB_DEFAULT_CLOSE_TEXT,
    bubbleSwitch = SB_DEFAULT_BUBBLE_SWTICH,
    appearAfterSecond = SB_DEFAULT_APPEAR_AFTER_SECOND,
    dismissAfterSecond = SB_DEFAULT_DISMISS_AFTER_SECOND,
    pageID,
    startDate,

    closeMode = SB_DEFAULT_CLOSE_MODE,
    shouldShowCloseIcon = SB_DEFAULT_SHOULD_SHOW_CLOSE_ICON,
  } = bannerContent;
  const sbId = `${pageID}-${startDate}`; // speechBubbleID
  const gaLob = select(getCustomerIsLife)
    ? 'Life'
    : select(getCustomerIsCwp)
    ? 'CWP'
    : '';
  const screenName: string = getGaPagePath();
  const tcWindow: any = window;

  React.useEffect(() => {
    // HKEMMA-100917 - GA3 Decommission - Remove GA3
    // if (!isApp()) {
    //   const token = select(getToken);
    //   const aToken = getValidToken(token);
    //   tcWindow.tC.event.clickBanner(tcWindow, {
    //     product: gaLob,
    //     bannerAction: 'display',
    //     bannerPlanName: gaLob,
    //     bannerTitle: headerTitle,
    //     screenName: screenName,
    //     AXAID: aToken,
    //   });
    // }

    tcWindow.dataLayer.push({
      event: 'clickBanner',
      product: gaLob,
      bannerAction: 'display',
      bannerPlanName: gaLob,
      bannerTitle: headerTitle,
      screenName: screenName,
    });
    trackBannerAction(gaLob, 'display', gaLob, headerTitle, screenName);

    throttledCtaBtnClickFunc = _.throttle(function (url) {
      // HKEMMA-100917 - GA3 Decommission - Remove GA3
      // if (!isApp()) {
      //   const token = select(getToken);
      //   const aToken = getValidToken(token);
      //   tcWindow.tC.event.clickBanner(tcWindow, {
      //     product: gaLob,
      //     bannerAction: 'click',
      //     bannerPlanName: gaLob,
      //     bannerTitle: headerTitle,
      //     screenName: screenName,
      //     AXAID: aToken,
      //   });
      // }

      tcWindow.dataLayer.push({
        event: 'clickBanner',
        product: gaLob,
        bannerAction: 'click',
        bannerPlanName: gaLob,
        bannerTitle: headerTitle,
        screenName: screenName,
      });
      trackBannerAction(gaLob, 'click', gaLob, headerTitle, screenName);

      if (isExternalLink(url)) {
        window.open(url);
      } else {
        const encodedDeeplink = url
          .replace('[[#', encodeURIComponent('[[#'))
          .replace('#]]', encodeURIComponent('#]]'));

        goto(encodedDeeplink); // deeplink
      }
    }, 1000);
  }, [content, landingUrl, headerTitle, gaLob]);

  React.useEffect(() => {
    function checkShouldHide() {
      if (dismissAfterSecond) {
        setShouldAppearNow(true);
        setTimeout(() => {
          setShouldAppearNow(false);
        }, dismissAfterSecond * 1000);
      }
    }
    if (appearAfterSecond) {
      setShouldAppearNow(false);
      setTimeout(() => {
        setShouldAppearNow(true);
        checkShouldHide();
      }, appearAfterSecond * 1000);
    } else {
      checkShouldHide();
    }
  }, [appearAfterSecond, dismissAfterSecond]);

  const defaultHeader = language === 'en' ? 'DO YOU KNOW' : '你知道嗎?';

  //TODO::::::::
  return (
    <div
      className="chatbot-speech-bubble chatbot-speech-bubble__container"
      style={
        !bubbleSwitch || !shouldAppearNow || !hasBeenClosed
          ? { display: 'none' }
          : {}
      }
    >
      {headerTitle && (
        <div className="chatbot-speech-bubble__headerImg">
          <img className="chatbot-speech-bubble__headerImg" src={ImgHeaderBg} />
          <div className="chatbot-speech-bubble__headerTitleImg">
            <img src={ImgTitleBgLeft} />
            {/* <img src={ImgTitleBgCenter} /> */}
            <span>{headerTitle || defaultHeader}</span>
            <img src={ImgTitleBgRight} />
          </div>
        </div>
      )}
      <img
        className="chatbot-speech-bubble__closeImage"
        src={ImgCloseButton}
        onClick={() => {
          // HKEMMA-100917 - GA3 Decommission - Remove GA3
          // if (!isApp()) {
          //   const token = select(getToken);
          //   const aToken = getValidToken(token);
          //   tcWindow.tC.event.clickBanner(tcWindow, {
          //     product: gaLob,
          //     bannerAction: 'close',
          //     bannerPlanName: gaLob,
          //     bannerTitle: headerTitle,
          //     screenName: screenName,
          //     AXAID: aToken,
          //   });
          // }
          tcWindow.dataLayer.push({
            event: 'clickBanner',
            product: gaLob,
            bannerAction: 'close',
            bannerPlanName: gaLob,
            bannerTitle: headerTitle,
            screenName: screenName,
          });
          trackBannerAction(gaLob, 'close', gaLob, headerTitle, screenName);
          setHasBeenClosed(false);
          onClose && onClose(sbId, closeMode, bannerContent);
        }}
        style={shouldShowCloseIcon ? {} : { display: 'none' }}
      />
      <div className="chatbot-speech-bubble__textContainer">
        <span className="chatbot-speech-bubble__contentText">{content}</span>
        <a
          onClick={() => {
            throttledCtaBtnClickFunc(landingUrl);
          }}
          data-enable-ga
          data-ga-type="button"
          className="chatbot-speech-bubble__buttonText"
        >
          {buttonText}
        </a>
        {buttonText2 && (
          <a
            onClick={() => {
              throttledCtaBtnClickFunc(landingUrl2);
            }}
            data-enable-ga
            data-ga-type="button"
            className="chatbot-speech-bubble__buttonText"
          >
            {buttonText2}
          </a>
        )}
        {!!closeText && (
          <a
            onClick={() => {
              // HKEMMA-100917 - GA3 Decommission - Remove GA3
              // if (!isApp()) {
              //   const token = select(getToken);
              //   const aToken = getValidToken(token);
              //   tcWindow.tC.event.clickBanner(tcWindow, {
              //     product: gaLob,
              //     bannerAction: 'close',
              //     bannerPlanName: gaLob,
              //     bannerTitle: headerTitle,
              //     screenName: screenName,
              //     AXAID: aToken,
              //   });
              // }
              tcWindow.dataLayer.push({
                event: 'clickBanner',
                product: gaLob,
                bannerAction: 'close',
                bannerPlanName: gaLob,
                bannerTitle: headerTitle,
                screenName: screenName,
              });
              trackBannerAction(gaLob, 'close', gaLob, headerTitle, screenName);
              setHasBeenClosed(false);
              onClose && onClose(sbId, closeMode, bannerContent);
            }}
            data-enable-ga
            data-ga-type="button"
            className="chatbot-speech-bubble__buttonText chatbot-speech-bubble__closeText"
          >
            {closeText}
          </a>
        )}
      </div>
      <img className="chatbot-speech-bubble__footerImg" src={ImgFooter} />
    </div>
  );
};

export default SpeechPopUp;
