import md5 from 'md5';

// @flow
// const SPEECH_BUBBLE_VALID_PLATFORM = ['All', 'APP'];
const DEFAULT_SHOW_PROBABILITY = 1;
export function getSpeechBubbleContent(
  speechBubbleList,
  previousScreenId,
  fetchedClosedSpeechBubble,
  closedSpeechBubbleFromCustState,
  closedSpeechBubbleFromLocalState: any[],
) {
  if (!fetchedClosedSpeechBubble) {
    return [];
  }
  const currentDateTime = new Date();
  const weekDayNum = currentDateTime.getDay();
  const hrStr = `${currentDateTime.getHours()}`.padStart(2, '0');
  const minStr = `${currentDateTime.getMinutes()}`.padStart(2, '0');
  const timeStr = `${hrStr}${minStr}`;

  const foundSpeechBubbleDetailsForPageId = speechBubbleList
    .filter((speechBubbleDetails: any) => speechBubbleDetails.pageID)
    .filter((speechBubbleDetails: any) => {
      try {
        // // platform filtering handled by API
        // if (speechBubbleDetails.platform && sbNotInCorrectPlatform(speechBubbleDetails.platform)) {
        //   return false;
        // }
        if (!speechBubbleDetails.bubbleSwitch) {
          return false;
        }
        const pageIdRegex = new RegExp(speechBubbleDetails.pageID, 'i');
        const isMatchedPageId = previousScreenId.match(pageIdRegex);
        if (!isMatchedPageId) {
          return false;
        }

        const { sbId } = speechBubbleDetails;

        // For close mode 1 & 3
        const isClosedBeforeInCustState =
          !!closedSpeechBubbleFromCustState.find(
            (sbObj) => sbObj && sbObj.sbId === sbId,
          );

        // For close mode 2 & 4
        const isClosedBeforeInLocalState =
          !!closedSpeechBubbleFromLocalState.find(
            (sbObj) => sbObj && sbObj.sbId === sbId,
          );
        if (isClosedBeforeInCustState || isClosedBeforeInLocalState) {
          return false;
        }

        // check week day
        if (
          speechBubbleDetails.occasionDay &&
          !speechBubbleDetails.occasionDay.includes(weekDayNum)
        ) {
          return false;
        }
        if (speechBubbleDetails.occassionTime) {
          const [startTime, endTime] =
            speechBubbleDetails.occassionTime.split('-');
          // startTime 0900, endTime 2300, timeStr 1900 (daytime case)
          if (
            startTime < endTime &&
            !(startTime <= timeStr && timeStr <= endTime)
          ) {
            return false;
          }
          // startTime 1800, endTime 0100, timeStr 1900 (overnight case)
          if (
            endTime < startTime &&
            !(endTime <= timeStr && startTime <= timeStr)
          ) {
            return false;
          }
        }
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    });
  if (
    !foundSpeechBubbleDetailsForPageId ||
    !foundSpeechBubbleDetailsForPageId.length
  ) {
    return [];
  }
  return foundSpeechBubbleDetailsForPageId;
}

export function foundSpeechBubbleContent(
  speechBubbleList,
  previousScreenId,
  fetchedClosedSpeechBubble,
  closedSpeechBubbleFromCustState,
  closedSpeechBubbleFromLocalState,
): boolean {
  return !!getSpeechBubbleContent(
    speechBubbleList,
    previousScreenId,
    fetchedClosedSpeechBubble,
    closedSpeechBubbleFromCustState,
    closedSpeechBubbleFromLocalState,
  ).length;
}

export function randomWithProbability(speechBubbleDetailsList) {
  const probArr = [];
  for (const speechBubbleDetails of speechBubbleDetailsList) {
    let { showProbability } = speechBubbleDetails;
    showProbability =
      Number.parseFloat(showProbability) || DEFAULT_SHOW_PROBABILITY;
    const chance = Math.floor(showProbability * 10);
    for (let i = 0; i < chance; i++) {
      probArr.push(speechBubbleDetails);
    }
  }
  if (!probArr.length) {
    return undefined;
  }
  const nowMinute = new Date().getMinutes();
  const chosenIndex = Math.floor(randomWithSeed(nowMinute) * probArr.length);
  return probArr[chosenIndex];
}

function randomWithSeed(seed) {
  const x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}

export function parseSpeechBubbleApiResponse(data) {
  if (
    !data ||
    !data.retrieveSpeechBubbleResponse ||
    !data.retrieveSpeechBubbleResponse.speechBubbleBanners ||
    !data.retrieveSpeechBubbleResponse.speechBubbleBanners.speechBubbleBanner
  ) {
    throw new Error('unexpected speech bubble response');
  }
  const combinedSpeechBubbleList = [];
  for (const profileObj of data.retrieveSpeechBubbleResponse.speechBubbleBanners
    .speechBubbleBanner) {
    const { items, profile } = profileObj;
    items.forEach((i) => {
      i.lobProfile = profile;
      i.sbId = getSbId(i);
    });
    combinedSpeechBubbleList.push(...items);
  }
  return combinedSpeechBubbleList;
}

export const getSbId = (data: any): string => {
  const hash = md5(
    `xx${data.closeMode}${data.content}${data.headerTitle || ''}`,
  );
  const thisSbId = `${data.pageID}-${data.startDate}-${hash.slice(0, 8)}`;
  return thisSbId;
};
