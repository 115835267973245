import {
  FETCH_APP_CONFIG_SUCCESS,
  FETCH_USER_CONFIG_SUCCESS,
} from '../actions/appActions';
import { FETCH_MOBILE_MODULE_CONFIG_SUCCESS } from '../actions/mobileModuleConfigAction';
import _ from 'lodash';

const appInitState = {
  moduleConfig: {},
  deepLinkMapping: {},
  systemStatus: {},
  preVersion: ``,
  postVersion: ``,
  avators: {},
  isUserConfigLoaded: false,
};

export default (state: any = appInitState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case FETCH_APP_CONFIG_SUCCESS:
      nextState.preVersion = action.config.preVersion;
      nextState.postVersion = action.config.postVersion;
      nextState.moduleConfig = _.merge(
        {},
        nextState.moduleConfig,
        action.config.moduleConfig,
      );
      nextState.deepLinkMapping = _.merge(
        {},
        nextState.deepLinkMapping,
        action.config.deepLinkMapping,
      );
      nextState.systemStatus = _.merge(
        {},
        nextState.systemStatus,
        action.config.systemStatus,
      );
      nextState.avators = _.merge({}, nextState.avators, action.config.avators);
      return nextState;

    case FETCH_USER_CONFIG_SUCCESS:
      nextState.postVersion = action.config.postVersion;
      nextState.moduleConfig = _.merge(
        {},
        nextState.moduleConfig,
        action.config.moduleConfig,
      );
      nextState.deepLinkMapping = _.merge(
        {},
        nextState.deepLinkMapping,
        action.config.deepLinkMapping,
      );
      nextState.systemStatus = _.merge(
        {},
        nextState.systemStatus,
        action.config.systemStatus,
      );
      nextState.avators = _.merge({}, nextState.avators, action.config.avators);
      nextState.isUserConfigLoaded = true;
      return nextState;

    case FETCH_MOBILE_MODULE_CONFIG_SUCCESS:
      nextState.moduleConfig = _.merge(
        {},
        nextState.moduleConfig,
        action.payload.config.moduleConfig,
      );
      nextState.deepLinkMapping = _.merge(
        {},
        nextState.deepLinkMapping,
        action.payload.config.deepLinkMapping,
      );
      nextState.isUserConfigLoaded = true;
      // We skip systemStatus and avators here. If need it, please add the field to app fetching logic.
      return nextState;
    default:
      return nextState;
  }
};
