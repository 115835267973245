// @flow
import { select, put } from '../stores';
import {
  getToken,
  getRefreshToken,
  getTokenFetchTime,
  getTokens,
  getTokenExpireIn,
  getAdditionalAccessTokens,
  getIsForceLogout,
} from '../selectors/authSelectors';
import {
  getRefreshAccessTokenApiUrl,
  getRequestAccessTokenApiUrl,
  getRefreshTokenAfter,
  getAdditionalAccessTokens as getAdditionalAccessTokensConfig,
  getMaamRedirectUri,
  getApiKey,
  //getAdditionalAccessTokenRefreshFailurePrompt
} from '../selectors/configSelectors';
import {
  removeExpiredToken,
  refreshTokenSuccess,
  refreshTokenFailure,
  fetchAccessTokenStarted,
  fetchAccessTokenSuccess,
  fetchAdditionalAccessTokenSuccess,
  refreshAdditionalAccessTokenSuccess,
  fetchAccessTokenFailure,
  signOut,
  checkForceLogout,
} from '../actions/authActions';
import {
  refreshSessionToken,
  fetchSessionAccessToken,
  expired,
  refreshSessionAdditionalToken,
  fetchSessionAdditionalToken,
} from '../actions/sessionAction';
//import { getRetryCount, getRetryDelay, getConnectionTimeout } from "@hk-myaxa-app-utils/selectors/configSelectors";
import moment from 'moment';
import _ from 'lodash';
import {
  AxaAuth,
  EmmaLoginAccessAdditionalToken,
  EmmaLoginConfig,
} from '../types/authTypes';
//import Cookies from 'cookies-js'
import { isApp, resolveLocalizedText, __DEV__ } from './index';
import uuidv4 from 'uuid/v4';
import { getValidToken, getGaClientId } from '../utils';
import Cookies from 'cookies-js';
import env from '../env';
import { getCorpWebCookieName } from '../modules/emma-webview/selectors/webviewSelector';
// import { findMock, findMockJson } from '../mock/index';
import { retryFnCall } from './retryUtil';
// import { findMock, findMockJson } from '../mock';

let refreshTokenInProgress = false;

const getConnectionTimeoutFromState = (): number => {
  // let state = store.getState();
  // return getConnectionTimeout(state);
  return 30;
};

const getRetryDelayByChance = (chance: number): number => {
  const retryCount = getRetryCountFromState();
  const trial = retryCount - chance;

  const increment: Array<number> = [1, 3, 5]; //getRetryDelay(state);

  const index = Math.min(trial, increment.length) - 1;
  const delay = increment[index] * 1000;

  return delay;
};

const tokenExpired = (): boolean => {
  const expiresIn: number = select(getTokenExpireIn);

  if (expiresIn) {
    const tokenFetchTime: string = select(getTokenFetchTime);
    const pastSecond = Math.abs(moment().diff(tokenFetchTime, 'second'));

    return pastSecond > expiresIn * 0.9;
  } else {
    return false;
  }
};

const tokenRefreshRequired = (): boolean => {
  const token = getTokenSync();

  if (!token) {
    return true;
  } else {
    const refreshTokenAfter: number = select(getRefreshTokenAfter);
    const tokenFetchTime: string = select(getTokenFetchTime);

    const pastSecond = Math.abs(moment().diff(tokenFetchTime, 'second'));

    return tokenExpired() || pastSecond > refreshTokenAfter;
  }
};

const getTokensFromState = (): Array<string> => {
  return select(getTokens);
};

const getRetryCountFromState = (): number => {
  return 3; //getRetryCount(state);
};

const getTokenSync = (): string => {
  return select(getToken);
};

const getTokenAwait = (chance = 30): Promise<string> => {
  return getTokenAwaitImpl().catch((err) => {
    return chance > 0
      ? getTokenAwait(--chance)
      : Promise.reject(`unable to refresh token for ${chance} seconds`);
  });
};

const getTokenAwaitImpl = (): Promise<string> => {
  const token = getTokenSync();

  return token
    ? Promise.resolve(token)
    : new Promise((resolve, reject) => {
        setTimeout(() => {
          reject('refresh token in progress...');
        }, 1000);
      });
};

const getRequestOptions = (authCode: string, apiKey: string) => {
  return {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Cache-Control': 'no-cache',
      apikey: apiKey,
      'x-axahk-msgid': uuidv4(),
      'x-axahk-code': authCode,
    },
  };
};

const requestTokenImpl = (
  authCode: string,
  authCodes: { [scope: string]: string },
): Promise<void> => {
  if (!refreshTokenInProgress) {
    put(fetchAccessTokenStarted());
    refreshTokenInProgress = true;

    //const state = store.getState();
    const apiKey = select(getApiKey);

    const additionalAccessTokens = select(getAdditionalAccessTokensConfig);
    const requests = Object.keys(authCodes).map((scope) => {
      const additionalAccessToken = additionalAccessTokens.find(
        (a) => a.scope === scope,
      ) as EmmaLoginAccessAdditionalToken;
      // const redirectUri = resolveLocalizedText(additionalAccessToken.redirectUri);
      return {
        url: additionalAccessToken.requestAccessTokenApiUrl,
        options: getRequestOptions(authCodes[scope], apiKey),
      };
    });

    const url = select(getRequestAccessTokenApiUrl);
    // const redirectUri = select(getMaamRedirectUri);
    requests.unshift({
      url,
      options: getRequestOptions(authCode, apiKey),
    });

    const fetchWithTimeoutPromises = requests.map((req) =>
      fetchWithTimeout(req.url, req.options),
    );

    return Promise.all(fetchWithTimeoutPromises)
      .then((responses) => {
        const response = responses.shift();

        if (!response) throw new Error();

        Promise.all(
          responses
            .filter((r) => r.status == 200)
            .map((r) => {
              const { scope } = additionalAccessTokens.find(
                (a) => a.requestAccessTokenApiUrl === r.url,
              ) as EmmaLoginAccessAdditionalToken;
              return r.json().then((json) => {
                return Promise.resolve({
                  scope,
                  token: json.access_token,
                  expiresIn: json.expires_in,
                  refreshToken: json.refresh_token,
                  fetchTime: moment().format(),
                });
              });
            }),
        ).then((result) => {
          put(
            fetchSessionAdditionalToken(
              result
                ? result.map((r) => {
                    return { scope: r.scope, token: r.token };
                  })
                : [],
            ),
          );
          put(fetchAdditionalAccessTokenSuccess(result));
        });

        return new Promise((res, rej) => {
          (response.status == 200
            ? response.json()
            : Promise.resolve(undefined)
          ).then((json) => {
            if (response.status == 200) {
              //ga#63
              try {
                const tcWindow: any = window;
                const aToken = getValidToken(json.access_token);

                const options = {
                  domain: 'axa.com.hk',
                  path: '/; sameSite=Lax;',
                  secure: true,
                };
                const corpWebCookieName = select(getCorpWebCookieName);
                Cookies.set(corpWebCookieName, aToken, options);

                retryFnCall(
                  () => {
                    tcWindow.tc_vars = tcWindow.tc_vars || {};
                    tcWindow.tc_vars.AXAID = aToken;
                    const clientID = getGaClientId();
                    tcWindow.dataLayer.push({
                      event: 'loginSuccessWithID',
                      clientID: clientID,
                      userId: aToken,
                    });

                    // HKEMMA-100917 - GA3 Decommission - Remove GA3
                    // if (!isApp()) {
                    //   tcWindow.tC.event.loginSuccessWithID(tcWindow, {
                    //     AXAID: aToken,
                    //     clientID: clientID,
                    //   });
                    // }
                  },
                  5,
                  3000,
                );
              } catch (e) {
                console.error(e);
              }

              // comment: remove A-HKG-0018-V010
              // put(fetchSessionAccessToken(json.access_token));
              put(
                fetchAccessTokenSuccess({
                  token: json.access_token,
                  expiresIn: json.expires_in,
                  refreshToken: json.refresh_token,
                  idToken: json.id_token,
                  fetchTime: moment().format(),
                }),
              );
              res();
            } else if (response.status == 504) {
              res();
              setTimeout(() => {
                put(fetchAccessTokenFailure(response));
              }, 500);
            } else {
              rej(response);
            }
          });
        });
      })
      .catch((err) => {
        return Promise.reject(err);
      })
      .then(() => {
        refreshTokenInProgress = false;
      });
  } else {
    return Promise.resolve();
  }
};

const delayedRequestToken = (
  authCode: string,
  authCodes: { [scope: string]: string },
  chance: number,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      requestToken(authCode, authCodes, chance)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    }, getRetryDelayByChance(chance));
  });
};

const requestToken = (
  authCode: string,
  authCodes: { [scope: string]: string },
  chance: number = getRetryCountFromState(),
): Promise<void> => {
  return requestTokenImpl(authCode, authCodes).catch((err) => {
    if (chance > 0) {
      return delayedRequestToken(authCode, authCodes, --chance);
    } else {
      put(fetchAccessTokenFailure(err));
      return Promise.reject(err);
    }
  });
};

const refreshAdditionalToken = (
  refreshTokenApiUrl: string,
  refreshToken: string,
  chance = getRetryCountFromState(),
): Promise<AxaAuth> => {
  return refreshAdditionalTokenImpl(refreshTokenApiUrl, refreshToken).then(
    (result) => {
      if (result) {
        return Promise.resolve(result);
      } else {
        if (chance > 0) {
          return delayedRefreshAdditionalToken(
            refreshTokenApiUrl,
            refreshToken,
            --chance,
          );
        } else {
          return Promise.reject('refreshAdditionalToken failure...');
        }
      }
    },
  );
};

const delayedRefreshAdditionalToken = (
  refreshTokenApiUrl: string,
  refreshToken: string,
  chance: number,
): Promise<AxaAuth> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      refreshAdditionalToken(refreshTokenApiUrl, refreshToken, chance)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    }, getRetryDelayByChance(chance));
  });
};

const refreshAdditionalTokenImpl = (
  refreshTokenApiUrl: string,
  refreshToken: string,
): Promise<AxaAuth | undefined> => {
  //let token = getTokenSync();
  return fetchWithTimeout(refreshTokenApiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({ refreshToken }),
  })
    .then((response) => {
      if (response.status == 504) {
        return Promise.resolve([null, response] as Array<any>);
      } else if (response.status == 200) {
        return response.json().then((json) => {
          return Promise.resolve([
            {
              token: json.access_token,
              expiresIn: json.expires_in,
              refreshToken: json.refresh_token,
              fetchTime: moment().format(),
            },
            null,
          ] as Array<any>);
        });
      } else {
        return Promise.resolve([null, null]);
      }
    })
    .catch((err: Error) => {
      return Promise.resolve([null, null]);
    })
    .then((results: Array<any>) => {
      const [result, error] = results;
      if (!result && !error) {
        return Promise.resolve(undefined);
      } else if (result) {
        return Promise.resolve(result);
      } else {
        return Promise.reject(error);
      }
    });
};

const validateAdditionalAccessTokens = (): Promise<void> => {
  const additionalAccessTokens = select(getAdditionalAccessTokens);
  return Promise.all(
    additionalAccessTokens.map((a) => validateAdditionalAccessToken(a.scope)),
  ).then(() => {});
};

const validateAdditionalAccessToken = (scope: string): Promise<void> => {
  const additionalAccessTokens = select(getAdditionalAccessTokens);
  const additionalAccessTokensConfig = select(getAdditionalAccessTokensConfig);
  const additionalAccessToken = additionalAccessTokens.find(
    (a) => a.scope === scope,
  );
  const additionalAccessTokenConfig = additionalAccessTokensConfig.find(
    (a) => a.scope === scope,
  );

  if (additionalAccessToken && additionalAccessTokenConfig) {
    const { refreshAccessTokenApiUrl, refreshTokenAfter } =
      additionalAccessTokenConfig;
    const { refreshToken, fetchTime, expiresIn } = additionalAccessToken;

    const pastSecond = Math.abs(moment().diff(fetchTime, 'second'));

    return !refreshToken || pastSecond < refreshTokenAfter
      ? Promise.resolve()
      : refreshAdditionalToken(refreshAccessTokenApiUrl, refreshToken).then(
          (result) => {
            put(refreshSessionAdditionalToken({ scope, token: result.token }));
            put(
              refreshAdditionalAccessTokenSuccess({
                scope,
                ...result,
              }),
            );
            return Promise.resolve();
          },
        );
  } else {
    return Promise.reject(
      'additionalAccessTokens or additionalAccessTokensConfig not available',
    );
  }
};

const refreshTokenImpl = (): Promise<void> => {
  //let token = getTokenSync();

  if (!refreshTokenInProgress) {
    refreshTokenInProgress = true;

    if (tokenExpired()) {
      put(expired());
      // put(removeExpiredToken());
    }

    const refreshToken = select(getRefreshToken);
    const refreshTokenApiUrl = select(getRefreshAccessTokenApiUrl);
    const apiKey = select(getApiKey);

    return fetchWithTimeout(refreshTokenApiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
        apikey: apiKey,
        'x-axahk-msgid': uuidv4(),
        'x-axahk-code': refreshToken,
      },
    })
      .then((response) => {
        if (response.status == 504) {
          put(refreshTokenFailure(response));
          refreshTokenInProgress = false;
          return Promise.resolve();
        } else if (response.status == 200) {
          return response.json().then((json) => {
            put(refreshSessionToken(json.access_token));
            put(
              refreshTokenSuccess({
                token: json.access_token,
                expiresIn: json.expires_in,
                refreshToken: json.refresh_token,
                idToken: json.id_token,
                fetchTime: moment().format(),
              }),
            );

            // setAxaAuth({
            //   token: json.access_token,
            //   expiresIn: json.expires_in,
            //   refreshToken: json.refresh_token,
            //   fetchTime: moment().format(),
            // });

            refreshTokenInProgress = false;
            return Promise.resolve();
          });
        } else {
          refreshTokenInProgress = false;
          return Promise.reject(response);
        }
      })
      .catch((err) => {
        refreshTokenInProgress = false;
        return Promise.reject(err);
      })
      .then(() => {
        refreshTokenInProgress = false;
      });
  } else {
    return Promise.resolve();
  }
};

const delayedRefreshToken = (chance: number): Promise<void> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      refreshToken(chance)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    }, getRetryDelayByChance(chance));
  });
};

const refreshToken = (
  chance: number = getRetryCountFromState(),
): Promise<void> => {
  return refreshTokenImpl().catch(async (err) => {
    try {
      const response = await err.json();

      if (isForceLogout(response)) {
        put(signOut());
        return Promise.reject(err);
      }
    } finally {
      if (chance > 0) {
        return delayedRefreshToken(--chance);
      } else {
        put(refreshTokenFailure(err));
        return Promise.reject(err);
      }
    }
  });
};

const isForceLogout = (response: Response) => {
  const errorCode = _.get(response, 'code', '');
  const LOGIN_ID_DOES_NOT_MATCH_ERROR = 'ERROR04';

  return errorCode === LOGIN_ID_DOES_NOT_MATCH_ERROR;
};

const fetchWithTimeout = (
  input: RequestInfo,
  init?: any,
): Promise<Response> => {
  const timeout = (init && init.timeout) || getConnectionTimeoutFromState();

  return new Promise((resolve, reject) => {
    const timer = setTimeout(
      () => reject(`request timeout: fetchConnectionTimeout=${timeout}`),
      timeout * 1000,
    );

    fetch(input, {
      ...init,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      })
      .then(() => clearTimeout(timer));
  });
};

const myAxaFetchImpl = (
  input: RequestInfo,
  init?: RequestInit,
  beforeRequest?: (options: RequestInit) => Promise<void>,
  onError?: (error: any, response: Response) => Promise<boolean>,
  timeout?: number,
  isNeedMsgid?: string,
): Promise<any> => {
  return (tokenRefreshRequired() ? refreshToken() : Promise.resolve())
    .catch((err) => {
      put(refreshTokenFailure(err));
      return Promise.reject(err);
    })
    .then(() => getTokenAwait())
    .then((token) => {
      // const authCookie = Cookies.get(`authorization`);
      // if(authCookie){
      //   const cookieToken = decodeURIComponent(authCookie).split(` `)[1];
      //   if(token != cookieToken){
      //     Cookies.set(`authorization`, `Bearer%20${token}`);
      //   }
      // }
      const msgidObj =
        isNeedMsgid === 'false'
          ? {}
          : {
              'x-axahk-msgid': uuidv4(),
            };
      const method = _.get(init, `method`, `POST`);

      const headers = Object.assign(
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          ...msgidObj,
        },
        _.get(init, `headers`, {}),
      );

      const options = Object.assign({}, init, { method, headers });

      const beforeRequestPromise =
        (beforeRequest && beforeRequest(options)) || Promise.resolve();

      return beforeRequestPromise
        .then(() =>
          fetchWithTimeout(
            input,
            timeout
              ? {
                  ...options,
                  timeout,
                }
              : options,
          ),
        )
        .then((response) => {
          const tokens = getTokensFromState();

          if (
            response.status >= 200 &&
            response.status < 400 &&
            tokens.indexOf(token) >= 0
          ) {
            return Promise.resolve(response);
          } else if (response.status === 401) {
            return response.json().then((json) => {
              if (json.exp && /token expired/i.test(json.exp)) {
                return refreshToken().then(() => {
                  return myAxaFetchWithToken(
                    input,
                    init,
                    beforeRequest,
                    onError,
                  );
                });
              } else {
                throw new Error();
              }
            });
          }
          return Promise.reject({ response });
        })
        .catch((err) => {
          const error = err.response ? null : err;
          const response: Response = err.response || null;

          return (
            onError &&
            onError(error, response).then((handled) => {
              return handled
                ? Promise.resolve(error || response)
                : Promise.reject(error || response);
            })
          );
        });
    });
};

const delayedMyAxaFetch = (
  input: RequestInfo,
  init?: RequestInit,
  beforeRequest?: (options: RequestInit) => Promise<void>,
  onError?: (error: any, response: any) => Promise<boolean>,
  chance?: number,
  isNeedMsgid?: string,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      myAxaFetchWithToken(
        input,
        init,
        beforeRequest,
        onError,
        chance,
        undefined,
        isNeedMsgid,
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    }, getRetryDelayByChance(chance || 0));
  });
};

const myAxaFetchWithToken = (
  input: RequestInfo,
  init?: RequestInit,
  beforeRequest: (options: RequestInit) => Promise<void> = () =>
    Promise.resolve(),
  onError: (error: any, response: any) => Promise<boolean> = () =>
    Promise.resolve(false),
  chance: number = getRetryCountFromState(),
  timeout?: number,
  isNeedMsgid?: string,
): Promise<Response> => {
  // if (__DEV__ && env.IS_ENABLE_MOCK) {
  //   const mock = findMock(input);
  //   if (mock) {
  //     console.warn(
  //       `Mocking API: ${JSON.stringify(input)}`,
  //       findMockJson(input),
  //     );
  //     return mock;
  //   }
  // }

  const isForceLogout = select(getIsForceLogout);

  if (isForceLogout) {
    put(checkForceLogout());
    return Promise.reject({
      code: 100,
      message: 'user has been force logged out...',
    });
  }

  const tokens = getTokensFromState();

  if (!tokens || tokens.length <= 0) {
    return Promise.reject({ code: 100, message: 'user has logged out...' });
  }

  return myAxaFetchImpl(
    input,
    init,
    beforeRequest,
    onError,
    timeout,
    isNeedMsgid,
  ).catch((err) => {
    return chance > 0
      ? delayedMyAxaFetch(
          input,
          init,
          beforeRequest,
          onError,
          --chance,
          isNeedMsgid,
        )
      : Promise.reject(err);
  });
};

export {
  myAxaFetchWithToken,
  refreshToken,
  requestToken,
  tokenRefreshRequired,
  fetchWithTimeout,
  validateAdditionalAccessToken,
  validateAdditionalAccessTokens,
};

// export const getAxaAuthFromRedux = () => {
//   const refreshToken = select(getRefreshToken);
//   const token = select(getToken);
//   const expiresIn = select(getTokenExpireIn);
//   const fetchTime = select(getTokenFetchTime);

//   return {
//     token,
//     expiresIn,
//     refreshToken,
//     fetchTime,
//   };
// };

export const getApiKeyFromRedux = () => {
  return select(getApiKey);
};
