import React, { Component, ComponentClass } from 'react';
import './styles.css';

import {
  getSignInUrl,
  getSignUpUrl,
  getWebVersion,
  getProfileVersion,
  getWebEnv,
} from '../../../../selectors/configSelectors';
import { connect } from 'react-redux';

import { t } from '../../../../locales';
import { setPostRedirectUri } from '../../../../actions/authActions';
import { extractRelativeUrl } from '../../../../utils';
import Header from '../../../../components/Header';
import env from '../../../../env/';
import { getLanguage } from '../../../../selectors/userSelectors';

import EmmaCarouselSlick from '../../../../components/EmmaCarouselSlick';
import LoginButton from './ab-test/components/LoginButton';
import withLoginButtonABTestHoc from './ab-test/withLoginButtonABTestHoc';

import { resolvePublicAssets } from '../../../../utils';
import { withLoadingWidgetHoc } from '../../../../components/lazyLoading/with-loading-widget-hoc';
import Lottie from 'lottie-react-web';
import { EmmaSpace, EmmaText } from '@axa-asia/emma-stylesheet';
import classNames from 'classnames';

interface WelcomeScreenProps {
  token: string;
  refreshToken: string;
  signInUrl: string;
  signUpUrl: string;
  lang: string;
  onSetPostRedirectUri: (uri: string) => void;
  webVersion: string;
  profileVersion: string;
  webEnv: string;
}

class WelcomeScreen extends Component<WelcomeScreenProps> {
  go(href: string) {
    window.location.href = href;
  }

  componentDidMount() {
    const { onSetPostRedirectUri } = this.props;

    const relativeUrl = extractRelativeUrl();
    onSetPostRedirectUri(relativeUrl);
  }

  render() {
    const { signInUrl, signUpUrl, webVersion, profileVersion, webEnv, lang } =
      this.props;
    const WrappedLoginButton = env.ENABLE_GOOGLE_OPTIMIZE_AB_TEST
      ? withLoginButtonABTestHoc(LoginButton)
      : LoginButton;
    return (
      <div className="App">
        <Header />
        <div className="welcome-screen__title">{t(`WELCOME_TITLE`)}</div>
        <div className="welcome-screen">
          <div className="welcome-screen__carousel">
            <Lottie
              options={{
                animationData: require('../../../../assets/images/animations/all-in-one-welcome.json'),
              }}
              width={327}
              height={327}
            />
            <span
              className={classNames(
                'welcome-screen__description-title',
                EmmaText['SmallModuleTitle/ComplexContent/Center/Indigo'],
                EmmaSpace.pl16,
                EmmaSpace.pr16,
              )}
            >
              {t('PRELOGIN_ALL_IN_ONE_TITLE')}
            </span>
            <span
              className={classNames(
                EmmaText['BodyTextS/short-paragraph-15em/Center/DustyGrey'],
                EmmaSpace.pl16,
                EmmaSpace.pr16,
              )}
            >
              {t('PRELOGIN_ALL_IN_ONE_WELCOME')}
            </span>
          </div>
          <div className="welcome-screen__buttons">
            <WrappedLoginButton signInUrl={signInUrl} go={this.go} />
            <button
              className="emma-stylesheet-primary-ghost-button"
              onClick={() => this.go(signUpUrl)}
              data-enable-ga
              data-ga-type="button"
            >
              {t(`WELCOME_REGISTER`)}
            </button>
            <div className="welcome-screen__version">
              {t('WELCOME_VERSION')} {webVersion || ''}
              {webEnv || ''}
              {profileVersion && ` / ${profileVersion}`}
            </div>
            <div style={{ fontSize: '14px', marginTop: '8px' }}>
              <a
                href={`/${lang}/emma/legal/privacy-policy`}
                target="_blank"
                data-enable-ga
                data-ga-type="link"
                rel="noreferrer"
              >
                {t('LOGIN_PRIVACY_POLICY')}
              </a>
              <span>{t('LOGIN_PRIVACY_AND')}</span>
              <a
                href={`/${lang}/emma/legal/terms-and-conditions`}
                target="_blank"
                data-enable-ga
                data-ga-type="link"
                rel="noreferrer"
              >
                {t('LOGIN_TERMS_AND_CONDITIONS')}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  static mapStateToProps(state: any, ownProps: any) {
    return {
      signInUrl: getSignInUrl(state),
      signUpUrl: getSignUpUrl(state),
      webVersion: getWebVersion(),
      profileVersion: getProfileVersion(state),
      webEnv: getWebEnv(),
      lang: getLanguage(state),
    };
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      onSetPostRedirectUri: (uri: string) => dispatch(setPostRedirectUri(uri)),
    };
  }
}

export default connect(
  WelcomeScreen.mapStateToProps,
  WelcomeScreen.mapDispatchToProps,
)(withLoadingWidgetHoc(WelcomeScreen));
