import { take, fork, call, put, takeLatest, select } from 'redux-saga/effects';
import { myAxaFetchWithToken } from '../../../utils/myAxaFetchWithToken';
import { getToken } from '../../../selectors/authSelectors';
import {
  FETCH_PROLICY_LIST_SUCCESS,
  RESET_PROLICY_LIST,
  FETCH_PROLICY_LIST_FEATURES_FAILURE,
  FETCH_PROLICY_LIST,
} from '../actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import uuid from 'uuid/v4';
import { getPolicyListApiUrl } from '../../../selectors/configSelectors';
import { FRAME_APP_MOUNTED } from '../../../actions/frameActions';
import {
  getFilterPolicyListByRegex,
  getPolicyHiddenRidersList,
} from '../selectors/policyListSelectors';
import { filterByRegex } from '../../../utils';
import _ from 'lodash';
import { isLifeOnlyInsuredPolicy, isOwnerPolicy } from '../../../utils/common';

export function* fetchPolicyListFeatures(action): Iterable<any> {
  do {
    try {
      const featureName = action?.payload?.featureName;
      const getRouter = (state: any) => state.router.location.pathname;
      const pathname = yield select(getRouter);
      const hiddenRiders = yield select(getPolicyHiddenRidersList);
      if (pathname.indexOf('/policy-list') > -1 || featureName) {
        let isbetterMeFlag = false;
        let isAxaGoalFlag = false;
        const navToList = pathname.split('/');
        let navTo = featureName ?? navToList[3];
        isbetterMeFlag =
          navToList.length > 4 && navToList[4] == 'betterme' ? true : false;
        if (navTo === 'viewleaflet') {
          navTo = 'mypolicy';
          isAxaGoalFlag = true;
        }
        const url =
          (yield select(getPolicyListApiUrl)) + `?featureName=${navTo}`;
        const token = select(getToken);
        if (token) {
          const res = yield call(myAxaFetchWithToken, url, {
            method: 'GET',
            headers: {
              'x-axahk-msgid': uuid(),
            },
          });
          // const res = { status: 200, json: () => require('../mock/feature.json') }
          let policies = yield res.json();
          const regex = yield select(getFilterPolicyListByRegex);
          policies = filterByRegex(policies, regex, 'policy'); // This will only used by Web, don't need to check isApp()
          policies = isbetterMeFlag
            ? policies.filter(
                (item: { policy: { betterMeFlag: string } }) =>
                  item.policy.betterMeFlag == 'Y',
              )
            : policies;
          policies = isAxaGoalFlag
            ? policies.filter(
                (item: { policy: { axaGoalPolicy: string } }) =>
                  item.policy.axaGoalPolicy === 'Y',
              )
            : policies;
          const returnPolicies: any = [];
          policies.forEach((item: any) => {
            const itemPolicy = {
              ...item,
              isLifeOnlyInsuredPolicy: isLifeOnlyInsuredPolicy(item.policy),
              isOwnerPolicy: isOwnerPolicy(item.policy),
            };
            returnPolicies.push(itemPolicy);
          });
          if (hiddenRiders) {
            returnPolicies.forEach((item: any) => {
              if (!item || !item.policyRiders || !item.policyRiders.length)
                return;
              item.policyRiders = item.policyRiders.filter((rider: any) => {
                if (!rider || !Object.keys(rider).length || !rider.planCode) {
                  return true;
                } else {
                  return !hiddenRiders.includes(rider.planCode);
                }
              });
            });
          }
          yield put({
            type: FETCH_PROLICY_LIST_SUCCESS,
            policies: returnPolicies,
          });
          yield action?.payload?.callback?.(returnPolicies);
        }
      }
    } catch (e) {
      yield put({ type: FETCH_PROLICY_LIST_FEATURES_FAILURE, error: e });
    }
    while (true) {
      const action = yield take(LOCATION_CHANGE);
      if (action.payload.location.pathname.indexOf('/policy-list') > -1) {
        yield put({ type: RESET_PROLICY_LIST });
        break;
      }
    }
  } while (true);
}

function* fetchPolicyList(): Iterable<any> {
  yield call(fetchPolicyListFeatures, null);
}

function* resetPolicyList(): any {
  yield put({ type: RESET_PROLICY_LIST });
}

function* polictListSaga() {
  // yield take(FRAME_APP_MOUNTED)
  yield fork(resetPolicyList);
  yield fork(fetchPolicyList);
  yield takeLatest(FETCH_PROLICY_LIST, fetchPolicyListFeatures);
}

export default polictListSaga;
