import { memoSanitize } from '@axa-asia/emma-safely-set-inner-html';
import React, { Component } from 'react';
import './styles.scss';

import { connect } from 'react-redux';

import { t } from '../../../../locales';
import Header from '../../../../components/Header';
import { getSystemStatus } from '../../../../selectors/systemSelectors';

import { getLanguage } from '../../../../selectors/userSelectors';

import EBFlexiBannerView from '../../../../components/EBFlexiBanner/EBFlexiBannerView';

import EmmaModalView from '../../../../components/EmmaModalView/EmmaModalView';

import { EB_FELXI_FROM } from '../../../../constants/constants';

import { signOut } from '../../../../actions/authActions';
import { getToken } from '../../../../selectors/authSelectors';
import MaintenanceCommonScreen from '../../../../components/MaintenanceCommonScreen';
import { withLoadingWidgetHoc } from '../../../../components/lazyLoading/with-loading-widget-hoc';
import EBFlexOverlayView from '../../../../components/EBFlexiBanner/EBFlexOverlayView';

interface MaintenanceScreenProps {
  message: any;
  token: string;
  onSignOut: () => void;
  isCommonContent: boolean;
  commonContent: any;
}

class MaintenanceScreen extends Component<MaintenanceScreenProps> {
  componentDidMount() {
    const { onSignOut } = this.props;
    setTimeout(() => {
      onSignOut();
    }, 15 * 60 * 1000);
  }

  render() {
    const {
      message,
      token,
      onSignOut,
      isCommonContent = false,
      commonContent,
    } = this.props;
    let content = message;
    if (message.zh && message.en) {
      const lang = getLanguage();
      content = message[lang];
    }
    content = content.replace('openurl:', '');

    let commonContentModel = commonContent;
    if (commonContentModel && commonContentModel.zh && commonContentModel.en) {
      const lang = getLanguage();
      commonContentModel = commonContentModel[lang];
    }

    return (
      <div className="App">
        <Header />
        <div>
          <EBFlexiBannerView type={EB_FELXI_FROM} />
          <EBFlexOverlayView type={EB_FELXI_FROM} />
        </div>
        {/* For EMMA WEB SYSTEM MAINTENANCE */}
        {!isCommonContent && (
          <div className="maintenance-screen">
            <div
              className="maintenance-screen__content"
              dangerouslySetInnerHTML={{ __html: memoSanitize(content) }}
            ></div>
            {!!token && (
              <div className="maintenance-screen__button-wrapper">
                <button
                  className="emma-stylesheet-primary-ghost-button"
                  onClick={onSignOut}
                  data-enable-ga
                  data-ga-type="button"
                >
                  {t(`LOGOUT`)}
                </button>
              </div>
            )}
          </div>
        )}
        {/* For Individual Life, Health, Savings & Investment Policyholders.*/}
        {isCommonContent && (
          <MaintenanceCommonScreen
            content={commonContentModel}
            onSignOut={onSignOut}
          />
        )}
        <EmmaModalView />
      </div>
    );
  }

  static mapStateToProps(state: any, ownProps: any) {
    return {
      ...getSystemStatus(state),
      token: getToken(state),
    };
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      onSignOut: () => dispatch(signOut()),
    };
  }
}

export default connect(
  MaintenanceScreen.mapStateToProps,
  MaintenanceScreen.mapDispatchToProps,
)(withLoadingWidgetHoc(MaintenanceScreen));
