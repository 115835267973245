import { EmmaFactory } from '@axa-asia/hk-emma-js';
import { maskPN } from '@axa-asia/hk-emma-web-module/lib/ga-lib/MaskingUtil';
import { getScreenNameAsync } from '@axa-asia/hk-emma-web-module/lib/ga-lib/WebScreenNameMappingUtil';
import { getPlatformStr, isApp } from './index';
import { getScreenNameMapping } from '../modules/google-analytics/selectors/homeSelector';
import { store } from '../stores';

/**
 * Translate screen path to screen name
 * @param screenPath
 * @returns
 */
const getScreenName = async (screenPath: string): Promise<string> => {
  const state = store.getState();
  const platformStr = getPlatformStr();
  const screenNameMapping = getScreenNameMapping(state);
  const screenName = (await getScreenNameAsync(
    screenPath,
    platformStr,
    screenNameMapping,
  )) as string;
  return screenName;
};

/**
 * Track default page view
 * GA4 - KPI #0
 */
export const trackDefaultPageViewGA4 = (
  curr_screen_name: string,
  previous_screen: string,
  curr_screen_path: string,
) => {
  if (isApp()) {
    const spa_curr_screen_name = maskPN(
      `SPA:${curr_screen_name}`.replace(/(\/)$/, ''),
    );
    const spa_previous_screen = maskPN(
      `SPA:${previous_screen}`.replace(/(\/)$/, ''),
    );
    const spa_curr_screen_path = maskPN(
      `SPA:${curr_screen_path}`.replace(/(\/)$/, ''),
    );
    EmmaFactory.createInstance().then((e) => {
      e.instance.trackScreen(
        spa_curr_screen_name,
        spa_previous_screen,
        spa_curr_screen_path,
      );
    });
  }
};

/**
 * GA KPI #9
 * @param icon
 */
export const trackAcceptDisclaimer = (icon: string) => {
  if (isApp()) {
    EmmaFactory.createInstance().then((e) => {
      e.instance.trackEvent('accept_disclaimer', {
        icon,
      });
    });
  }
};

/**
 * GA KPI #26
 * @param product
 * @param section_name
 * @param benefit_item
 * @param selected_policy
 */
export const trackBenefitCoverageExpandCollapse = (
  product: string,
  section_name: string,
  benefit_item: string,
  selected_policy: string,
) => {
  if (isApp()) {
    EmmaFactory.createInstance().then((e) => {
      e.instance.trackEvent('expand_collapse_benefit_coverage', {
        product,
        section_name,
        benefit_item,
        selected_policy,
      });
    });
  }
};

/**
 * GA KPI #27
 * @param product
 * @param document_name
 */
export const trackDownloadDocuments = (
  product: string,
  document_name: string,
) => {
  if (isApp()) {
    EmmaFactory.createInstance().then((e) => {
      e.instance.trackEvent('download_document', {
        product,
        document_name,
      });
    });
  }
};

/**
 * GA KPI #28
 * @param product
 * @param selected_policy
 */
export const trackBenefitCoverageContactUs = (
  product: string,
  selected_policy: string,
) => {
  if (isApp()) {
    EmmaFactory.createInstance().then((e) => {
      e.instance.trackEvent('contact_us_coverage', {
        product,
        selected_policy,
      });
    });
  }
};

/**
 * GA KPI #30
 * @param product
 * @param section_name
 * @param benefit_item
 * @param selected_policy
 */
export const trackBenefitCoverageMakeAppointment = (
  product: string,
  section_name: string,
  benefit_item: string,
  selected_policy: string,
) => {
  if (isApp()) {
    EmmaFactory.createInstance().then((e) => {
      e.instance.trackEvent('make_appointment_coverage', {
        product,
        section_name,
        benefit_item,
        selected_policy,
      });
    });
  }
};

/**
 * GA KPI #31
 * @param product
 * @param service_provider_name
 * @param service_provider_phone
 */
export const trackBenefitCoverageCallProvider = (
  product: string,
  service_provider_name: string,
  service_provider_phone: string,
) => {
  if (isApp()) {
    EmmaFactory.createInstance().then((e) => {
      e.instance.trackEvent('call_service_provider_from_coverage', {
        product,
        // service_provider_name, // PII not sending
        // service_provider_phone, // PII not sending
      });
    });
  }
};

/**
 * GA KPI #32
 * @param product
 * @param section_name
 * @param benefit_item
 * @param displayed_button_text
 * @param selected_policy
 */
export const trackBenefitCoverageCheckDoctorList = (
  product: string,
  section_name: string,
  benefit_item: string,
  displayed_button_text: string,
  selected_policy: string,
) => {
  if (isApp()) {
    EmmaFactory.createInstance().then((e) => {
      e.instance.trackEvent('check_coverage_network_doctor_list', {
        product,
        section_name,
        benefit_item,
        displayed_button_text,
        selected_policy,
      });
    });
  }
};

/**
 * GA4 KPI #41
 * @param bare_screen_path
 * @param lead_type
 * @param lead_product
 * @param lead_source
 * @param lead_id
 */
export const trackSubmitLeadForm = (
  bare_screen_path: string,
  lead_type: string,
  lead_product: string,
  lead_source: string,
  lead_id: string,
) => {
  if (isApp()) {
    const screen_path = maskPN(bare_screen_path);
    getScreenName(screen_path).then((screen_name) => {
      EmmaFactory.createInstance().then((e) => {
        e.instance.trackEvent('submit_my_policy_lead_form', {
          screen_path,
          screen_name,
          lead_type,
          lead_product,
          lead_source,
          lead_id,
        });
      });
    });
  }
};

/**
 * GA4 KPI #47
 */
export const trackScrollDepthGA4 = (
  bare_screen_path: string,
  scroll_direction: string,
  scroll_depth_threshold: string,
) => {
  if (isApp()) {
    const screen_path = maskPN(bare_screen_path);
    getScreenName(screen_path).then((screen_name) => {
      EmmaFactory.createInstance().then((e) => {
        e.instance.trackEvent('scroll_depth', {
          screen_path,
          screen_name,
          scroll_direction,
          scroll_depth_threshold,
        });
      });
    });
  }
};

/**
 * GA4 KPI #55
 * @param product
 * @param banner_action
 * @param banner_plan_name
 * @param banner_title
 * @param screen_name
 */
export const trackBannerAction = (
  product: string,
  banner_action: string,
  banner_plan_name: string,
  banner_title: string,
  bare_screen_path: string,
  event_name?: string,
) => {
  if (isApp()) {
    const screen_path = maskPN(bare_screen_path);
    getScreenName(screen_path).then((screen_name) => {
      EmmaFactory.createInstance().then((e) => {
        e.instance.trackEvent(event_name ? event_name : 'click_banner', {
          product,
          banner_action,
          banner_plan_name,
          banner_title,
          screen_path,
          screen_name,
        });
      });
    });
  }
};

/**
 * GA4 KPI #56
 * @param video_status
 * @param video_name
 * @param video_current_time
 * @param screen_name
 */
export const trackWatchVideoProgress = (
  video_status: string,
  video_name: string,
  video_current_time: string,
  bare_screen_path: string,
) => {
  if (isApp()) {
    const screen_path = maskPN(bare_screen_path);
    getScreenName(screen_path).then((screen_name) => {
      EmmaFactory.createInstance().then((e) => {
        e.instance.trackEvent('watch_benefit_coverage_video_progress', {
          video_status,
          video_name,
          video_current_time,
          screen_path,
          screen_name,
        });
      });
    });
  }
};

/**
 * GA4 KPI #58
 * @param video_play_method
 * @param video_name
 * @param screen_name
 */
export const trackPlayVideo = (
  video_play_method: string,
  video_name: string,
  bare_screen_path: string,
) => {
  if (isApp()) {
    const screen_path = maskPN(bare_screen_path);
    getScreenName(screen_path).then((screen_name) => {
      EmmaFactory.createInstance().then((e) => {
        e.instance.trackEvent('play_benefit_coverage_video', {
          video_play_method,
          video_name,
          screen_path,
          screen_name,
        });
      });
    });
  }
};

/**
 * GA4 KPI #61
 * @param product
 * @param section_name
 * @param selected_benefit_type
 * @param service_item
 * @param selected_policy
 */
export const trackChooseBenefit = (
  product: string,
  section_name: string,
  selected_benefit_type: string,
  service_item: string,
  selected_policy: string,
) => {
  if (isApp()) {
    EmmaFactory.createInstance().then((e) => {
      e.instance.trackEvent('choose_benefit', {
        product,
        section_name,
        selected_benefit_type,
        service_item,
        selected_policy,
      });
    });
  }
};

/**
 * GA4 KPI #62
 * @param {*} displayed_button_text
 * @param {*} screen_name
 */

export const trackCtaButton = (
  displayed_button_text: string,
  bare_screen_path: string,
) => {
  if (isApp()) {
    const screen_path = maskPN(bare_screen_path);
    getScreenName(screen_path).then((screen_name) => {
      EmmaFactory.createInstance().then((e) => {
        e.instance.trackEvent('click_cta_button', {
          displayed_button_text,
          screen_path,
          screen_name,
        });
      });
    });
  }
};

/**
 * GA4 KPI #66
 * @param {*} action
 * @param {*} policyNumber
 */
export const trackModifyInsuredAccess = (
  action: string,
  policyNumber: string,
) => {
  if (isApp()) {
    EmmaFactory.createInstance().then((e) => {
      e.instance.trackEvent('modify_insured_access', {
        action,
        // policy_number: policyNumber,
      });
    });
  }
};

/**
 * GA4 KPI #67
 * @param {*} screenName
 * @param {*} action
 * @param {*} content
 */
export const trackUpdateProfileContact = (
  bare_screen_path: string,
  action: string,
  content: string,
) => {
  if (isApp()) {
    const screen_path = maskPN(bare_screen_path);
    getScreenName(screen_path).then((screen_name) => {
      EmmaFactory.createInstance().then((e) => {
        e.instance.trackEvent('update_profile_contact', {
          screen_path,
          screen_name,
          action,
          content,
        });
      });
    });
  }
};

/**
 * GA4 KPI #68
 * @param {*} insuredAccessSystemStatus
 * @param {*} policuNumber
 */
export const trackClickRemindInsured = (
  insuredAccessSystemStatus: string,
  policuNumber: string,
) => {
  if (isApp()) {
    EmmaFactory.createInstance().then((e) => {
      e.instance.trackEvent('click_remind_insured', {
        insured_access_system_status: insuredAccessSystemStatus,
        // policy_number: policuNumber,
      });
    });
  }
};

/**
 * GA4 KPI #70
 * @param {*} screenName
 * @param {*} error_message
 */
export const trackDisplayError = (
  bare_screen_path: string,
  error_message: string,
) => {
  if (isApp()) {
    const screen_path = maskPN(bare_screen_path);
    getScreenName(screen_path).then((screen_name) => {
      EmmaFactory.createInstance().then((e) => {
        e.instance.trackEvent('display_error', {
          screen_path,
          screen_name,
          error_message,
        });
      });
    });
  }
};
