import React from 'react';
import { ComponentClass } from 'react';

class RootComponentRegistry {
  private component?: ComponentClass;
  private componentReady?: () => void;

  onComponentReady(callback: () => void) {
    this.componentReady = callback;
  }

  /** component is ComponentClass */
  set(component: any) {
    this.component = component;
    this.componentReady && this.componentReady();
  }

  empty() {
    return !this.component;
  }

  render(props: {}) {
    if (this.component) {
      const Component = this.component;
      return <Component {...props} />;
    } else {
      return null;
    }
  }
}

export default new RootComponentRegistry();
